<template>
  <validation-observer ref="refFormReservation">
    <!-- ANCHOR - PASSENGER -->
    <PassengerDetailClassBooking
      v-if="getSearchFlight"
      :customer-id="customerId"
      :passenger-data-to-add.sync="sortedPassengers"
      :data-search-flight="getSearchFlight"
      :selected-trip="selectedTrip"
      @deletePassenger="handleDeletePassenger"
      @multipleQuantityPaxChange="handleMultipleQuantityPaxChange"
    />

    <!-- ANCHOR CONTACT -->
    <b-card
      class="mt-1 shadow"
      no-body
    >
      <!-- ANCHOR Thông tin AGC -->
      <BookingAgencyDetail
        :agency-data.sync="agencyData"
        :checkboxCustomerDataReplacePhoneAgencyData.sync="checkboxCustomerDataReplacePhoneAgencyData"
        :checkboxCustomerDataReplaceEmailAgencyData.sync="checkboxCustomerDataReplaceEmailAgencyData"
        :is-role-f1="isRoleF1"
        :enable-send-employee-mail-airline="enableSendEmployeeMailAirline"
        :is-collapsed="isCollapsedAgencyAndContactDetail"
        @toggleCollapse="toggleIsCollapsedAgencyAndContactDetail"
      />

      <!-- ANCHOR Thông tin khách hàng -->
      <BookingContactDetail
        :customer-data.sync="customerData"
        :checkboxCustomerDataReplacePhoneAgencyData.sync="checkboxCustomerDataReplacePhoneAgencyData"
        :checkboxCustomerDataReplaceEmailAgencyData.sync="checkboxCustomerDataReplaceEmailAgencyData"
        :is-role-f3="isRoleF3"
        :is-collapsed="isCollapsedAgencyAndContactDetail"
        @toggleCollapse="toggleIsCollapsedAgencyAndContactDetail"
        @resetAgencyData="resetAgencyData"
      />
    </b-card>

    <!-- ANCHOR Remark -->
    <b-card
      v-if="!isProduction"
      header-class="py-50 px-1"
      body-class="mx-1"
      no-body
    >
      <template #header>
        <div class="d-flex align-items-center">
          <img
            src="@icons/edit-remark.svg"
            alt="Remark"
            width="24px"
            class="mr-50"
          >
          <span class="font-medium-1 font-weight-bolder">
            Remark
          </span>
        </div>
      </template>

      <div class="mx-1 mb-75">
        <b-form-textarea
          id="textarea"
          v-model="textRemark"
          class=""
          placeholder="Thêm remark"
          rows="1"
        />
      </div>
    </b-card>

    <!-- ANCHOR AccountCode -->
    <b-card
      v-if="!isProduction"
      body-class="px-50 py-1 shadow"
      no-body
    >
      <b-input-group class="input-group-merge border-info rounded">
        <b-input-group-prepend
          is-text
          class="prepend_account_code"
        >
          <span>AccountCode: </span>
        </b-input-group-prepend>
        <b-form-input
          id="AccountCode"
          v-model="accountCodeInput"
          class="pl-50 font-medium-2 text-body"
          placeholder="Account Code..."
        />
      </b-input-group>
    </b-card>

    <!-- ANCHOR - BUTTON Tính giá, đặt chỗ -->
    <b-card
      class="mb-0"
      body-class="p-75"
    >
      <!-- <div
        v-if="['VN', '1G'].includes(airlineSearch)"
        class="mb-1"
      >
        <b-form-checkbox
          v-model="isBargainFinder"
          name="retain-checkbox"
          class="custom-control-warning"
        >
          <span class="font-weight-bold text-body">
            Tự động tính giá rẻ nhất của hành trình đã chọn
          </span>
        </b-form-checkbox>
      </div> -->

      <div class="d-flex-center">
        <b-button
          v-if="['1G', 'VN1A'].includes(airlineSearch)"
          variant="info"
          class="mr-1"
          pill
          @click="handleOpenCalculatePriceModal('reservation')"
        >
          <feather-icon
            icon="DollarSignIcon"
            size="16"
            class="mr-25"
          />
          {{ $t('reservation.recalculatePrice.title') }}
        </b-button>

        <b-button
          variant="warning"
          class="px-2 px-md-3"
          pill
          @click="handleValidateBooking"
        >
          {{ $t('flight.createBooking') }}
        </b-button>
      </div>
    </b-card>

    <!-- <BModal
      :id="`modal-create-booking-confirm-auto-bargain-finder`"
      title="Đặt giữ chỗ"
      title-class="text-airline font-medium-3 font-weight-bolder"
      header-class="px-1 py-50"
      body-class="px-50"
      centered
      no-close-on-backdrop
      @show="handleShowModalCreateBooking"
    >
      <template #modal-footer="{ close }">
        <BButton
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill px-2 mr-1"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </BButton>

        <BButton
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient px-2 px-md-3"
          pill
          @click="createBookingHandle(bookingDataToAdd)"
        >
          <span class="align-middle"> Giữ chỗ </span>
        </BButton>
      </template>

      <div class="d-flex">
        <span class="font-medium-2 mr-75">Tự động tính giá rẻ nhất khi đặt booking?</span>
        <b-form-checkbox v-model="isAutoBargainFinder" />
      </div>
    </BModal> -->

    <ModalCalcPriceClassBooking :modal-id="'reservation'" />
  </validation-observer>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ref,
  computed, watch,
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import { getUserData } from '@/api/auth/utils'
import env from '@/libs/env'

import { convertISODateTime } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'
import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BInputGroup,
    BModal,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormTextarea,
    ValidationObserver,

    BookingContactDetail: () => import('@flightv2/reservation/BookingContactDetail.vue'),
    BookingAgencyDetail: () => import('@flightv2/reservation/BookingAgencyDetail.vue'),
    PassengerDetailClassBooking: () => import('@flightv2/result-class-booking/reservation/PassengerDetailClassBooking.vue'),

    ModalCalcPriceClassBooking: () => import('@flightv2/result-class-booking/components/ModalCalcPriceClassBooking.vue'),
  },
  // props: {
  //   isEveryDomesticFlights: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },
  setup(_, { emit }) {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      createBooking,
      commandCreateBooking,
      getSearchFlight,
    } = useBookingHandle()

    const {
      store,
      toastError,
      toastWarning,
      toastSuccess,
      isEmpty,
      groupBy,
      cloneDeep,
      isSelectAllTrip,
      airlineSearch,
      countPaxs,
      dataAddMoreFlight,
      dataTripsToCalcPrice,
      isEveryDomesticFlights,
      handleOpenCalculatePriceModal,
    } = useClassBookingHandle()

    const passengerData = {
      firstName: '',
      lastName: '',
      title: '',
      birthday: '',
      isSetBirthday: false,
      paxType: '',
      paxId: '1',
      PaxDocuments: [
        {
          paxId: '',
          docType: 'P',
          number: '',
          nationality: '',
          residence: '2023-06-16',
          issCountry: '',
          startDate: '',
          endDate: '',
          isCountry: '',
        },
      ],
    }

    const memberFlyData = {
      memberFly: {
        cardNumber: null,
        airline: 'VN', // FIXME: membership card airline VU?
      },
    }

    // const isBargainFinder = ref(false)

    const flightSegmentStatus = ref('NN')
    const selectedTrip = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedTripClassBooking`])
    const dataSearchClassBooking = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getResultSearchClassBooking`])
    const customerData = ref(null)
    const customerId = computed(() => customerData.value?.paxId || 0)
    // const status = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusClassBooking`]))

    const textRemark = ref('')
    const accountCodeInput = ref('')

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const agencyDataStore = computed(() => store.getters['userStore/getAgencyData'])
    const meDataStore = computed(() => store.getters['userStore/getMeData'])
    const enableSendEmployeeMailAirline = computed(() => agencyDataStore.value?.enableSendEmployeeMailAirline && isRoleF2.value)

    const passengerDataToAdd = ref([{ ...cloneDeep(passengerData), paxType: 'ADULT', ...cloneDeep(memberFlyData) }])
    const checkboxCustomerDataReplacePhoneAgencyData = ref(false)
    const checkboxCustomerDataReplaceEmailAgencyData = ref(false)

    // const agencyData = ref({ ...store.getters['userStore/getMeData'] })
    const agencyData = ref(isRoleF1.value
      ? { ...meDataStore.value }
      : {
        ...agencyDataStore.value,
        phoneNumber: agencyDataStore.value?.agencyOwner?.phoneNumber,
      })

    const isProduction = computed(() => process.env.VUE_APP_ENV === 'production')

    const { isAutoCheckboxInContactsInfo } = env

    function resetAgencyData() { agencyData.value = { ...store.getters['userStore/getMeData'] } }
    const addPassenger = (paxType, addMore = false) => {
      const passenger = {
        ...passengerData,
        paxType,
        paxId: `${passengerDataToAdd.value.length + 1}`,
        PaxDocuments: passengerData.PaxDocuments.map(PaxDocument => ({ ...PaxDocument, paxId: `${passengerDataToAdd.value.length + 1}` })),
        ...cloneDeep(memberFlyData),
      }

      if (['INFANT'].includes(paxType)) {
        passenger.parentPaxId = null
        passenger.isSetBirthday = true
      }

      if ((['CHILD'].includes(paxType)) && ['1G'].includes(airlineSearch.value)) {
        passenger.isSetBirthday = true
      }

      if (addMore) store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/addPassenger`, paxType)

      passengerDataToAdd.value.push(passenger)
    }

    watch(() => getSearchFlight.value, newData => {
      const { adult, child, infant } = newData

      // if (totalPassenger <= totalSearchPassengers) {
      passengerDataToAdd.value = [{ ...cloneDeep(passengerData), paxType: 'ADULT', ...cloneDeep(memberFlyData) }]

      for (let i = 1; i < adult; i += 1) {
        addPassenger('ADULT')
      }
      for (let i = 0; i < child; i += 1) {
        addPassenger('CHILD')
      }
      for (let i = 0; i < infant; i += 1) {
        addPassenger('INFANT')
      }
    }, { immediate: true })

    const updatePassengerCountInLocalStorage = (paxType, add = false) => {
      const searchFlight = JSON.parse(localStorage.getItem('searchFlight'))

      if (add) searchFlight[paxType.toLowerCase()] += 1
      else searchFlight[paxType.toLowerCase()] -= 1

      localStorage.setItem('searchFlight', JSON.stringify(searchFlight))
    }

    const handleAddPassenger = paxType => {
      if (passengerDataToAdd.value.filter(p => p.paxType === 'ADULT').length + passengerDataToAdd.value.filter(p => p.paxType === 'CHILD').length === 9 && (paxType === 'ADULT' || paxType === 'CHILD')) {
        toastError({
          title: 'flight.passengerMore9',
        })
      } else if (paxType === 'INFANT' && (getSearchFlight.value.adult <= getSearchFlight.value.infant)) {
        toastError({
          title: 'flight.maxLengthInfant',
        })
      } else {
        addPassenger(paxType, true)
        updatePassengerCountInLocalStorage(paxType, true)
      }
    }

    // NOTE - SORT LIST PASSENGERS
    const sortedPassengers = computed(() => {
      const order = { ADULT: 1, CHILD: 2, INFANT: 3 }

      const result = passengerDataToAdd.value.slice().sort((a, b) => {
        const paxTypeA = a.paxType.toUpperCase()
        const paxTypeB = b.paxType.toUpperCase()

        if (order[paxTypeA] < order[paxTypeB]) {
          return -1
        }
        if (order[paxTypeA] > order[paxTypeB]) {
          return 1
        }
        return 0
      })

      return result
    })

    // ANCHOR - DELETE PASSENGER
    function handleDeletePassenger(passenger) {
      if ((sortedPassengers.value.filter(p => p.paxType === 'ADULT').length === 1) && (passenger.paxType === 'ADULT')) {
        toastError({
          title: 'flight.cannotDeletePassenger',
          content: 'flight.leastOneAdult',
        })
        return
      }

      if (passenger.deleteAny) {
        const paxLists = cloneDeep(passengerDataToAdd.value)
        const lastPaxEqualPaxType = paxLists.filter(pax => pax.paxType === passenger.paxType)

        if (!isEmpty(lastPaxEqualPaxType)) {
          const paxToDel = lastPaxEqualPaxType[lastPaxEqualPaxType.length - 1]
          if (['ADULT'].includes(passenger.paxType) && !isEmpty(paxLists.filter(p => p.paxType === 'INFANT'))) {
            const infantHasParentIndex = paxLists.findIndex(p => p.parentPaxId && p.parentPaxId === paxToDel.paxId)
            if (infantHasParentIndex !== -1) {
              paxLists[infantHasParentIndex] = { ...cloneDeep(paxLists[infantHasParentIndex]), parentPaxId: null }
            }
          }
          passengerDataToAdd.value = cloneDeep(paxLists.filter(pax => pax.paxId !== paxToDel.paxId))
          store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/deletePassenger`, passenger.paxType)
          // if (passenger.paxType !== 'INFANT') addDataToSeatTable(passengerDataToAdd.value)
        }
      } else {
        const titleMsg = () => this.$createElement('div', {
          domProps: { innerHTML: this.$t('flight.confirmDeletePassenger') },
        })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('modal.yes'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              if (passenger.paxType === 'ADULT' && !isEmpty(passengerDataToAdd.value.filter(p => p.paxType === 'INFANT'))) {
                const infantHasParentIndex = passengerDataToAdd.value.findIndex(p => p.parentPaxId && p.parentPaxId === passenger.paxId)
                if (infantHasParentIndex !== -1) {
                  passengerDataToAdd.value[infantHasParentIndex].parentPaxId = null
                }
              }

              passengerDataToAdd.value = passengerDataToAdd.value.filter(el => el.paxId !== passenger.paxId)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/deletePassenger`, passenger.paxType)
              // if (passenger.paxType !== 'INFANT') addDataToSeatTable(passengerDataToAdd.value)
            }
          })
      }
    }

    const blankBookingData = ref({
      type: '',
      flightType: null,
      contact: getUserData().employeeData.id,
      paxContact: {
        paxCode: '',
        firstName: '',
        lastName: '',
        phone: '',
        homePhone: '',
        address: '',
        country: 'VN',
        language: '',
        email: '',
        paxId: null,
      },
      paxLists: [],
      itineraries: [],
      // splitItineraries: false,
    })

    const bookingDataToAdd = ref(cloneDeep(blankBookingData.value))

    const errLists = [
      {
        code: 'Request failed with status code 500',
        label: 'Vui lòng kiểm tra lại thông tin và thử lại!',
      },
      {
        code: 'EnhancedAirBookRQ: UNABLE 00 AVAILABLE',
        label: 'Vé đã chọn hết chỗ, vui lòng thay đổi hành trình và thử lại!',
      },
      {
        code: 'NO FARE FOR CLASS USED',
        label: 'Vui lòng chọn hạng vé khác và thử lại!',
      },
      {
        code: 'The waitlist has been closed, please select another flight.',
        label: 'Danh sách chờ đã bị đóng, vui lòng chọn chuyến bay khác!',
      },
    ]

    // function createBookingHandle(payload) {
    //   store.dispatch('app/setLoading', true)
    //   createBooking(payload)
    //     .then(res => {
    //       store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDataCreatedBookingClassBooking`, res.resBooking)
    //       toastSuccess({ title: 'messagesList.success', content: 'Đặt giữ chỗ thành công!' })
    //       bookingDataToAdd.value = cloneDeep(blankBookingData.value)

    //       emit('scroll-to-create-booking')
    //     })
    //     .catch(err => {
    //       bookingDataToAdd.value = cloneDeep(blankBookingData.value)
    //       const codeErr = err?.response?.data?.message
    //       if (codeErr) {
    //         const checkErrCode = errLists.find(item => item.code === codeErr)
    //         const dataErr = checkErrCode ? checkErrCode.label : codeErr
    //         store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setErrCreateBookingText`, dataErr)
    //       }

    //       emit('scroll-to-create-booking') // FIXME: scroll to booking
    //       toastError({ title: 'messagesList.error', content: err.message })

    //       console.error({ err })
    //     })
    //     .finally(() => {
    //       store.dispatch('app/setLoading', false)
    //     })
    // }

    // const isAutoBargainFinder = ref(true)

    async function createBookingHandle(payload) {
      // if (['VN1A'].includes(airlineSearch.value) && isAutoBargainFinder.value) {
      //   bookingDataToAdd.value.bargainFinder = isAutoBargainFinder.value
      // }

      try {
        store.dispatch('app/setLoading', true)
        const res = ['VN1A'].includes(airlineSearch.value) ? await commandCreateBooking(payload) : await createBooking(payload)
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDataCreatedBookingClassBooking`, res.resBooking)
        toastSuccess({ title: 'messagesList.success', content: 'Đặt giữ chỗ thành công!' })
        bookingDataToAdd.value = cloneDeep(blankBookingData.value)
        // this.$bvModal.hide('modal-create-booking-confirm-auto-bargain-finder')
        emit('scroll-to-create-booking')
      } catch (error) {
        bookingDataToAdd.value = cloneDeep(blankBookingData.value)
        const codeErr = error?.response?.data?.message
        if (codeErr) {
          const checkErrCode = errLists.find(item => item.code === codeErr)
          const dataErr = checkErrCode ? checkErrCode.label : codeErr
          store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setErrCreateBookingText`, dataErr)
        }
        emit('scroll-to-create-booking') // FIXME: scroll to booking
        toastError({ title: 'messagesList.error', content: error.message })
        console.error({ error })
      } finally {
        store.dispatch('app/setLoading', false)
      }
    }

    function modifyPayload() {
      if (!customerData.value) {
        bookingDataToAdd.value.paxContact = null
      } else if (customerData.value.firstName || customerData.value.lastName) {
        bookingDataToAdd.value.paxContact = customerData.value
      } else {
        bookingDataToAdd.value.paxContact = {
          ...customerData.value,
          firstName: sortedPassengers.value[0].firstName,
          lastName: sortedPassengers.value[0].lastName,
        }
      }

      bookingDataToAdd.value.flightType = getSearchFlight.value.type
      // bookingDataToAdd.value.bargainFinder = false

      // ANCHOR - AIRLINE CONTACT INFO
      bookingDataToAdd.value.airlineContact = {
        phoneNumber: checkboxCustomerDataReplacePhoneAgencyData.value || (isAutoCheckboxInContactsInfo && !isRoleF3.value && customerData.value?.phone) ? customerData.value.phone : agencyData.value.phoneNumber,
        email: (checkboxCustomerDataReplaceEmailAgencyData.value || (isAutoCheckboxInContactsInfo && !isRoleF3.value)) && customerData.value?.email ? customerData.value.email : agencyData.value.emailAddress,
      }

      // ANCHOR - TRIPS INFO
      const statusSegment = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusClassBooking`]))
      const groupPassengers = groupBy(passengerDataToAdd.value, item => item.paxType)
      selectedTrip.value.forEach((trip, indexTrip) => {
        if (!bookingDataToAdd.value.type) {
          bookingDataToAdd.value.type = airlineSearch.value
        }

        if (['VU', 'VN1A'].includes(airlineSearch.value)) {
          bookingDataToAdd.value.splitItineraries = false
        }

        bookingDataToAdd.value.itineraries.push({
          source: airlineSearch.value, // ['VU'].includes(trip[0].airline) ? 'VU' : '1G',
          airline: trip[0].airline,
          clientId: '',
          bookingKey: '',
          fareBreakdowns: Object.keys(groupPassengers).map(paxType => {
            let net = 0
            let total = 0

            if (['ADULT'].includes(paxType)) {
              net = trip[0].bookingClass.prices.find(p => ['ADULT'].includes(p.paxType))?.net || 0
              total = trip[0].bookingClass.prices.find(p => ['ADULT'].includes(p.paxType))?.fare || 0
            }

            return {
              paxType,
              netFare: net,
              discountAmount: 0,
              discountAmountParent: 0,
              tax: 0,
              total,
              fareValue: '',
            }
          }),
          segments: trip.map((segment, indexSegment) => ({
            leg: indexSegment + 1,
            airline: segment.airline,
            departure: segment.departure,
            arrival: segment.arrival,
            departureTime: convertISODateTime(segment.departureDate, segment.departureTimezone).ISOdatetime,
            arrivalTime: convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).ISOdatetime,
            fareType: '',
            fareBasisCode: '',
            bookingClass: segment.bookingClass.code,
            groupClass: segment?.bookingClass?.groupClass || '',
            marriageGrp: '',
            segmentValue: segment?.segmentValue || segment?.bookingClass?.prices[0]?.segmentValue || '',
            bookingClassId: segment?.bookingClassId || '',
            segmentId: trip.length > 1 ? String(indexSegment + 1) : '',
            operating: segment.operating || null,
            flightNumber: String(segment.flightNumber),
            status: statusSegment.value[indexTrip][indexSegment],
          })),
        })
      })

      if (!isEmpty(dataAddMoreFlight.value)) {
        // FIXME here push more flight
        dataAddMoreFlight.value.forEach(trip => {
          const dataTrip = {
            source: airlineSearch.value, // ['VU'].includes(trip[0].airline) ? 'VU' : '1G',
            airline: trip.segments[0].airline,
            clientId: '',
            bookingKey: '',
            fareBreakdowns: Object.keys(groupPassengers).map(paxType => {
              let net = 0
              let total = 0

              if (['ADULT'].includes(paxType)) {
                net = 0 // trip[0].bookingClass.prices.find(p => ['ADULT'].includes(p.paxType))?.net || 0
                total = 0 // trip[0].bookingClass.prices.find(p => ['ADULT'].includes(p.paxType))?.fare || 0
              }

              return {
                paxType,
                netFare: net,
                discountAmount: 0,
                discountAmountParent: 0,
                tax: 0,
                total,
                fareValue: '',
              }
            }),
            segments: trip.segments.map((segment, indexSegment) => ({
              leg: indexSegment + 1,
              airline: segment.airline,
              departure: segment.departure,
              arrival: segment.arrival,
              departureTime: convertISODateTime(segment.departureDate, segment.departureTimezone).ISOdatetime,
              arrivalTime: convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).ISOdatetime,
              fareType: '',
              fareBasisCode: '',
              bookingClass: segment.bookingClass.code,
              groupClass: segment?.bookingClass?.groupClass || '',
              marriageGrp: '',
              segmentValue: segment?.segmentValue || segment?.bookingClass?.prices?.[0]?.segmentValue || '',
              bookingClassId: segment?.bookingClassId || '',
              segmentId: trip.length > 1 ? String(indexSegment + 1) : '',
              operating: segment.operating || null,
              flightNumber: String(segment.flightNumber),
              status: segment.status,
            })),
          }
          bookingDataToAdd.value.itineraries.push(dataTrip)
        })
      }

      // ANCHOR - PAXLIST INFO
      bookingDataToAdd.value.paxLists = cloneDeep(passengerDataToAdd.value).map(passenger => {
        if (!passenger.memberFly.cardNumber) {
          delete passenger.memberFly
        } else {
          passenger.memberFly.supplierCode = passenger?.memberFly?.airline
        }
        return {
          ...passenger,
          PaxDocuments: [],
          ...(passenger.memberFly && {
            memberFly: [{
              cardNumber: String(passenger.memberFly.cardNumber),
              airline: passenger.memberFly.airline,
              supplierCode: passenger.memberFly.supplierCode,
            }],
          }),
          // PaxDocuments: selectedTrip.value[0].source === '1G' ? passenger.PaxDocuments.filter(item => item.startDate && item.endDate && item.number && item.nationality) : [],
          birthday: (passenger.birthday) ? new Date(passenger.birthday).toISOString() : undefined,
        }
      })
    }

    function handleValidateBooking() {
      const isSelectTrip = isSelectAllTrip(selectedTrip.value, dataSearchClassBooking.value)
      if (isSelectTrip) {
        this.$refs.refFormReservation.validate()
          .then(success => {
            if (success) {
              modifyPayload()

              if (['VN1A'].includes(airlineSearch.value)) {
                const totalLengthPaxSeats = countPaxs.value.reduce((total, item) => total + (['ADULT', 'CHILD'].includes(item.code) ? item.count : 0), 0)
                const isInValidSeats = selectedTrip.value.some(trip => trip.some(seg => !Number.isNaN(Number(seg.bookingClass.availability)) && Number(seg.bookingClass.availability) < totalLengthPaxSeats))
                || dataAddMoreFlight.value.some(trip => trip.segments.some(seg => !Number.isNaN(Number(seg.bookingClass.availability)) && Number(seg.bookingClass.availability) < totalLengthPaxSeats))

                if (isInValidSeats) {
                  toastWarning({
                    title: 'Có hành trình không đủ số ghế cho tất cả hành khách, vui lòng kiểm tra và chọn lại !',
                  })
                  return
                }
              }

              // if (['VN1A'].includes(airlineSearch.value)) {
              //   this.$bvModal.show('modal-create-booking-confirm-auto-bargain-finder')
              // } else {
              createBookingHandle(bookingDataToAdd.value)
              // }
            } else {
              toastError({ title: 'messagesList.error', content: 'Vui lòng kiểm tra lại và nhập đầy đủ thông tin!' })
            }
          })
      }
    }

    function handleMultipleQuantityPaxChange(data) {
      data.forEach(item => {
        if (['increase'].includes(item.action)) {
          for (let i = 0; i < item.target; i += 1) {
            addPassenger(item.key, true)
          }
        }
        if (['decrease'].includes(item.action)) {
          for (let i = 0; i < item.target; i += 1) {
            const data = {
              paxType: item.key,
              deleteAny: true,
            }
            handleDeletePassenger(data)
          }
        }
      })
    }

    const isCollapsedAgencyAndContactDetail = ref(true)
    function toggleIsCollapsedAgencyAndContactDetail() {
      isCollapsedAgencyAndContactDetail.value = !isCollapsedAgencyAndContactDetail.value
    }

    // function handleShowModalCreateBooking() {
    // console.log({ bookingDataToAdd: bookingDataToAdd.value })
    //
    // }

    return {
      selectedTrip,
      flightSegmentStatus,
      isEmpty,
      airlineSearch,

      customerData,
      customerId,
      isRoleF1,
      isRoleF3,
      enableSendEmployeeMailAirline,

      sortedPassengers,
      passengerDataToAdd,
      getSearchFlight,

      // backHandle,
      convertISODateTime,

      handleAddPassenger,
      handleDeletePassenger,
      handleValidateBooking,

      agencyData,
      checkboxCustomerDataReplacePhoneAgencyData,
      checkboxCustomerDataReplaceEmailAgencyData,
      resetAgencyData,

      textRemark,
      accountCodeInput,
      handleOpenCalculatePriceModal,
      dataTripsToCalcPrice,
      isEveryDomesticFlights,
      handleMultipleQuantityPaxChange,

      isProduction,

      isCollapsedAgencyAndContactDetail,
      toggleIsCollapsedAgencyAndContactDetail,
      // isAutoBargainFinder,
      createBookingHandle,
      bookingDataToAdd,
      // handleShowModalCreateBooking,
    }
  },
}
</script>

<style lang="scss">
  .prepend_account_code {
    div.input-group-text {
      color: white;
      background-color: #3DA5DE !important;
      border: 1px solid #3DA5DE !important;
    }
  }
</style>
